// External
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Data/Service/Lib/Const
import { FEATURE_FLAGS } from '@/constants/featureFlags';
// Wrappers
import { Card } from '@/wrappers/Primary/Card';
import { InPlay } from '@/features/tradeManager/pages/TradeManager/tabs/InPlay/InPlay';
import PlacedBetsWrapper from '@/wrappers/PlacedBets/PlacedBets';
import TradeManagerWrapper from '@/wrappers/TradeManager/TradeManager';
import BetApprovalsWrapper from '@/wrappers/BetApprovals/BetApprovals';
// Features
import Upcoming from '@/features/bets/pages/PlacedBets/tabs/Upcoming/Upcoming';
import CustomMarkets from '@/features/tradeManager/pages/TradeManager/tabs/CustomMarkets';
import NextToJumpTab from '@/features/tradeManager/pages/TradeManager/tabs/NextToJump';
import BetsApprovalsList from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
// Routes
import { BetsApprovalHistoryList } from '@/routes/betApprovals/betApprovals';
import { Resulted } from '@/routes/placedBets/placedBets';
import {
  BySportTab,
  FindAnEventTab,
  HighestExposureTab,
  ManageGlobalMarginsTab,
  ManageMyCustomMarketsTab,
  MatchDetails,
  MultiMonitor,
  SameGameMultiTab,
} from '@/routes/tradeManager/tradeManager';

export default function EmbeddedRoutes() {
  return (
    <Routes>
      {/* Placed Bets */}
      <Route path="/placed-bets">
        <Route element={<Card noBottomPad />}>
          <Route element={<PlacedBetsWrapper />}>
            <Route index element={<Navigate to="upcoming" />} />
            <Route path="upcoming" element={<Upcoming />} />
            <Route path="resulted" element={<Resulted />} />
          </Route>
        </Route>
      </Route>

      {/* Bet Approvals */}
      <Route path="/bet-approvals">
        <Route element={<Card noBottomPad />}>
          <Route element={<BetApprovalsWrapper />}>
            <Route index element={<Navigate to="approvals" />} />
            <Route path="approvals" element={<BetsApprovalsList />} />
            <Route path="history" element={<BetsApprovalHistoryList />} />
          </Route>
        </Route>
      </Route>

      {/* Trade Manager */}
      <Route path="/trade-manager">
        <Route element={<Card noBottomPad />}>
          <Route element={<TradeManagerWrapper />}>
            <Route index element={<NextToJumpTab />} />
            {FEATURE_FLAGS.IS_IN_PLAY_ENABLED && (
              <Route path="in-play" element={<InPlay />} />
            )}
            <Route path="by-sport" element={<BySportTab />} />
            <Route path="highest-exposure" element={<HighestExposureTab />} />
            <Route path="find-an-event" element={<FindAnEventTab />} />
            {FEATURE_FLAGS.IS_SGM_ENABLED && (
              <Route path="sgm" element={<SameGameMultiTab />} />
            )}
            <Route
              path="manage-my-custom-markets"
              element={<ManageMyCustomMarketsTab />}
            />
            <Route
              path="manage-global-margins"
              element={<ManageGlobalMarginsTab />}
            />
            <Route path="multi-monitor" element={<MultiMonitor />} />
            <Route path="custom-markets" element={<CustomMarkets />} />
          </Route>
        </Route>
        <Route path="match-details" element={<MatchDetails />} />
      </Route>
    </Routes>
  );
}
