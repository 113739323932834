import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { TRequestBet } from '../../../features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.types';
import { BonusBet } from './EventMarketInfo.styles';
import { GiftPack } from '../../../assets/icons';
import Countdown from '../../../components/Countdown/Countdown';
import { Header } from './components/Header';
import { Multis } from './components/Multis';
import {
  TOTE_MULTI_DISPLAY_NAME,
  getIconAssetPath,
  getStrings,
} from '../../utils';
import { Box } from '@chakra-ui/react';
import { isWincore } from '@/features/betApprovals/pages/BetApprovals/tabs/Approvals';
import { MarketTableItem } from '../BetsTable/components/MarketTableItem';
import { normalizeBetApproval } from '@/features/betApprovals/pages/BetApprovals/tabs/Services/BetApprovals.utils';
import IconSvg from '@/components/IconSvg/IconSvg';

export type TEventMarketInfo = {
  bet: TRequestBet;
};

const [
  {
    BetApprovalsPage: { Table: Strings },
  },
] = getStrings();

export const EventMarketInfo = ({ bet }: TEventMarketInfo) => {
  const isPlacement = bet?.request_type?.toLowerCase() === 'placement';
  const isExoticBet = bet?.type?.toLowerCase() === 'exotics';
  const isSRMultiBet = bet?.type?.toLowerCase() === 'srmulti';
  const isSGMultiBet = bet?.type?.toLowerCase() === 'sgmulti';
  const isMulti = !!bet?.bet_legs;
  const isToteMultiBet = bet?.type?.toLowerCase() === 'totemulti';

  const isTote =
    (bet?.bet_legs &&
      (bet?.bet_legs[0]?.price_type === 'tote_single_mid' ||
        bet?.bet_legs[0]?.price_type === 'tote_single_best')) ||
    bet?.price_type === 'tote_single_mid' ||
    bet?.price_type === 'tote_single_best';

  const marketName = isWincore
    ? bet?.bet_legs && bet?.bet_legs[0]?.event_data?.market_name
    : bet?.sub_type;

  if (isToteMultiBet)
    return (
      <Flex flexDir="column">
        <Text fontWeight="bold" fontSize="md">
          {bet?.bet_legs?.[0]?.event_data?.venue_name} - R
          {bet.tote_pool_legs?.map((leg) => leg).join(', ')}
        </Text>
        <Text mt="1">
          <IconSvg
            name={getIconAssetPath('sports', bet?.bet_legs?.[0]?.event_icon)}
            sx={{ boxSize: '4', mr: '2', cursor: 'pointer' }}
          />
          {TOTE_MULTI_DISPLAY_NAME[marketName]}
        </Text>

        {bet.tote_pool_legs?.map((tote, index) => (
          <Text key={index}>
            R{tote}{' '}
            {bet.selection?.[index]?.map((selection) => selection).join(', ')}
          </Text>
        ))}
      </Flex>
    );

  return (
    <Flex direction="column" alignItems="start">
      {isWincore ? (
        <>
          <MarketTableItem
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data={normalizeBetApproval(bet) as any}
            type={bet.type as string}
          />
        </>
      ) : (
        <Header bet={bet} />
      )}
      {!isWincore &&
        isPlacement &&
        !isSRMultiBet &&
        !isSGMultiBet &&
        !isMulti && (
          <Countdown
            eventTime={
              (isWincore
                ? bet?.bet_legs && bet?.bet_legs[0]?.event_start
                : bet?.event_start) ?? ''
            }
          />
        )}
      {!isWincore && bet?.is_bonus_bet && (
        <BonusBet>
          <GiftPack width="20" /> <Text>{Strings.Bonus}</Text>
        </BonusBet>
      )}
      {!isWincore && bet?.bet_legs && !isTote && !isExoticBet && (
        <Multis bet={bet} />
      )}
      {!isWincore && isPlacement && (isSRMultiBet || isSGMultiBet) && (
        <Box paddingTop={2}>
          <Countdown
            eventTime={
              (isWincore
                ? bet?.bet_legs && bet?.bet_legs[0]?.event_start
                : bet?.event_start) ?? ''
            }
          />
        </Box>
      )}

      {isTote && bet.bet_legs && (
        <Box paddingTop={2}>
          {dayjs(bet.bet_legs && bet.bet_legs[0]?.event_start).format(
            'HH:mmA DD/MM'
          )}
        </Box>
      )}
    </Flex>
  );
};
