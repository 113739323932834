import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';
import { MutationCache, QueryCache, QueryClient } from 'react-query';
import store from './store';

import { firebaseConfig } from '../lib/firebase/config';

import { AppContainer } from './Styles';
import Routes from '../routes/Routes';
import Providers from './components/Providers';
import ErrorPage from '../features/errorpage';
import { HTTPError } from '@/lib/api/types';
import { useErrorHandler } from './services/app.hooks';
import EmbeddedRoutes from '../routes/EmbeddedRoutes';
import { isEmbeddedApp } from '@/lib/embed';

const appErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here

  console.error('Error handler', { error, info });
};

const App: FC = () => {
  initializeApp(firebaseConfig);

  // 👀 This is used by the error boundary. On error - the user clicks 'try again' and this triggers the browser router to reload
  // https://github.com/bvaughn/react-error-boundary#error-recovery
  const [errorKey, setErrorKey] = React.useState(false);

  const { errorHandler } = useErrorHandler();

  const queryClient = new QueryClient({
    queryCache: new QueryCache({
      onError: (err) => errorHandler({ error: err as HTTPError }),
    }),
    mutationCache: new MutationCache({
      onError: (err) => errorHandler({ error: err as HTTPError }),
    }),
  });

  if (isEmbeddedApp()) {
    return (
      <Providers client={queryClient}>
        <Provider store={store}>
          <BrowserRouter>
            <ErrorBoundary
              FallbackComponent={ErrorPage}
              onError={appErrorHandler}
              onReset={() => setErrorKey(false)}
              resetKeys={[errorKey]}
            >
              <EmbeddedRoutes />
            </ErrorBoundary>
          </BrowserRouter>
        </Provider>
      </Providers>
    );
  }

  return (
    <Providers client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={ErrorPage}
            onError={appErrorHandler}
            onReset={() => setErrorKey(false)}
            resetKeys={[errorKey]}
          >
            <Toaster />
            <AppContainer>
              <Routes />
            </AppContainer>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </Providers>
  );
};

export default App;
